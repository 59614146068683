import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Observable } from 'rxjs';
import { AccountRegisterRequestDTO } from '../DTOs/AccountRegisterRequestDTO';
import { AccountLoginRequestDTO } from '../DTOs/AccountLoginRequestDTO';
import { AccountLoginResponseDTO } from '../DTOs/AccountLoginResponseDTO';
import { AccountRefreshTokenRequestDTO } from '../DTOs/AccountRefreshTokenRequestDTO';
import { AccountRefreshTokenResponseDTO } from '../DTOs/AccountRefreshTokenResponseDTO';
import { UpdateUserDetailsDTO } from '../DTOs/UpdateUserDetailsDTO';
import { ForgotPasswordRequestDTO } from '../DTOs/ForgotPasswordRequestDTO';
import { NewPasswordRequestDTO } from '../DTOs/NewPasswordRequestDTO';
import { IUser } from '../models/user';
import { ChangePasswordDTO } from '../DTOs/ChangePasswordDTO';

@Injectable({
  providedIn: 'root',
})
export class AccountControllerService {
  private readonly _resourceApi = '/Account';

  constructor(private apiService: ApiService) {}

  register(body: AccountRegisterRequestDTO): Observable<boolean> {
    return this.apiService.post(`${this._resourceApi}/register`, body);
  }

  login(body: AccountLoginRequestDTO): Observable<AccountLoginResponseDTO> {
    return this.apiService.post(`${this._resourceApi}/login`, body);
  }

  forgotPassword(body: ForgotPasswordRequestDTO): Observable<boolean> {
    return this.apiService.post(
      `${this._resourceApi}/forgot-password?email=${body.email}`
    );
  }

  newPassword(body: NewPasswordRequestDTO): Observable<boolean> {
    return this.apiService.post(`${this._resourceApi}/new-password`, body);
  }

  refreshToken(
    body: AccountRefreshTokenRequestDTO
  ): Observable<AccountRefreshTokenResponseDTO> {
    return this.apiService.post(`${this._resourceApi}/refresh-token`, body);
  }

  updateUser(body: UpdateUserDetailsDTO): Observable<UpdateUserDetailsDTO> {
    return this.apiService.post(
      `${this._resourceApi}/update-user-details`,
      body
    );
  }

  logout(): Observable<boolean> {
    return this.apiService.delete(`${this._resourceApi}/logout`);
  }

  changePassword(body: ChangePasswordDTO): Observable<IUser> {
    return this.apiService.post(`${this._resourceApi}/reset-password`, body);
  }
}

<div class="p-3 flex flex-col justify-between h-full text-center">
  <div class="flex justify-between items-center">
    <h1 class="text-[1.5rem]">
      {{ _data.title ? _data.title : "Confirm Action" }}
    </h1>
    <mat-icon class="text-[3rem] !w-[3rem] !h-[3rem]">error_outline</mat-icon>
  </div>
  <p>
    {{ _data.content ? _data.content : "Are you sure you want to do this?" }}
  </p>
  <div class="flex gap-3 justify-center items-center">
    <button (click)="onConfirm()" class="bg-[#FE6313] text-white py-1 px-[2rem] rounded-[.2rem]">
      {{ _data.confirmButtonLabel ? _data.confirmButtonLabel : "Yes" }}
    </button>
    <button (click)="onDismiss()" class="bg-black text-white py-1 px-[2rem] rounded-[.2rem]">
      {{ _data.dismissButtonLabel ? _data.dismissButtonLabel : "No" }}
    </button>
  </div>
</div>


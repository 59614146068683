import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../_core/services/user.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  redirectRoute: string;
  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.userService.isLoggedIn$.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.redirectRoute = '/admin/overview';
      } else {
        this.redirectRoute = '/';
      }
    });
  }
}

import { Component, Input } from '@angular/core';
import { CropperModalComponent } from '../image-uploader/cropper-modal/cropper-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MediaOneResponseDTO } from '../../../_core/DTOs/MediaOneResponseDTO';
import { MediaControllerService } from '../../../_core/api/media-controller.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { IAlbum, Lightbox, LightboxConfig } from 'ngx-lightbox';
import { FormArray, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-multiple-image-uploader',
  templateUrl: './multiple-image-uploader.component.html',
  styleUrls: ['./multiple-image-uploader.component.scss'],
  providers: [MatSnackBar],
})
export class MultipleImageUploaderComponent {
  @Input() imageFormControl: FormArray;
  isLoading: boolean;
  imagesArray: IAlbum[];

  constructor(
    private dialogRef: MatDialog,
    private mediaController: MediaControllerService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private lightbox: Lightbox,
    private lightboxConfig: LightboxConfig
  ) {
    lightboxConfig.centerVertically = true;
    lightboxConfig.disableScrolling = true;
    lightboxConfig.wrapAround = true;
    lightboxConfig.showImageNumberLabel = true;
    lightboxConfig.albumLabel = 'Image %1 of %2';
  }

  ngOnInit(): void {
    this.imagesArray = this.images;
  }

  open(index: number): void {
    // open lightbox
    this.lightbox.open(this.imagesArray, index);
  }

  fileChangeEvent(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (!target.value) return;
    const dialog = this.dialogRef.open(CropperModalComponent, {
      height: '30rem',
      width: '80rem',
      data: { e: event },
    });
    dialog.afterClosed().subscribe((res: { data: File }) => {
      if (res !== undefined) {
        this.isLoading = true;
        this.imageFormControl.markAsTouched();
        if (!res?.data) {
          this.isLoading = false;
          this.snackBar.open('File size is too large', 'Close', {
            duration: 3000,
          });
          return;
        }
        this.mediaController.uploadImage(res.data).subscribe({
          next: (res: MediaOneResponseDTO) => {
            this.imageFormControl.push(this.fb.control(res.url));
            this.isLoading = false;
          },
          error: () => {
            this.snackBar.open('Upload failed', 'Close', { duration: 3000 });
            this.isLoading = false;
          },
        });
      }
      target.value = '';
    });
  }

  removeImage(i: number): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: 'Delete image',
          content: 'Are you sure you want to delete this image?',
          confirmButtonLabel: 'Delete',
          dismissButtonLabel: 'Cancel',
          customSize: false,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.imageFormControl.removeAt(i);
        }
      });
  }

  get images(): IAlbum[] {
    const items: IAlbum[] = this.imageFormControl.value.map((image: string) => {
      return {
        src: image + '/xl',
      } as IAlbum;
    });
    return items;
  }

  get errors(): string[] {
    if (this.imageFormControl.errors)
      return Object.keys(this?.imageFormControl?.errors)
        .filter((key) => this.imageFormControl.errors[key])
        .map((err) => {
          switch (err) {
            case 'required':
              return 'This field is required.';
            case 'minlength':
              return 'You need to upload at least 4 images.';
            default:
              return err[0].toUpperCase() + err.slice(1);
          }
        });
    else return [];
  }
}

<button
  [disabled]="disabled || isLoading"
  [class]="
    'bg-[#FE6313] w-full rounded-xl text-white font-sans font-bold hover:bg-[#c24c10] transition-colors flex items-center justify-center gap-3 py-5 disabled:bg-[#FE631399] disabled:cursor-default ' +
    styleClass
  "
>
  <span *ngIf="!isLoading" class="flex justify-center items-center gap-2">
    <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
    {{ label }}
  </span>
  <span *ngIf="isLoading">
    <mat-spinner class="!h-[1.5em] white-spinner"></mat-spinner>
  </span>
</button>

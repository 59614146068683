import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { MediaOneResponseDTO } from '../DTOs/MediaOneResponseDTO';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MediaControllerService {
  private readonly _resourceApi = '/Media';

  constructor(private apiService: ApiService) {}

  uploadImage(file: File): Observable<MediaOneResponseDTO> {
    const formData = new FormData();
    formData.append('file', file);
    return this.apiService.post(`${this._resourceApi}/one`, formData);
  }
}

<div
  class="flex lg:flex-row flex-col gap-8 lg:items-center bg-[#151A1A] p-8 justify-between lg:h-[20rem] lg:w-full"
>
  <div class="flex flex-col gap-4 lg:w-1/4">
    <div>
      <img src="../../../../assets/Logo.png" alt="Page Logo" />
    </div>
    <p class="lg:w-[25rem] text-[#737373]">
      © Copyright 2024 Wildveld Expedition Motor Company.
      <br />
      All rights reserved.
    </p>
  </div>
  <div class="flex gap-4 flex-1 lg:w-1/2 justify-between pr-8">
    <div class="flex flex-col gap-3 text-[#8A8C8C] lg:w-1/2">
      <h2 class="text-white text-xl font-bold uppercase">Explore</h2>
      <a routerLink="/defenders">Vehicles</a>
      <a routerLink="/restoration">Restoration</a>
      <a href="/shop">Conservation</a>
      <a href="/faq">FAQ</a>
      <a routerLink="/about">About</a>
    </div>
    <div class="flex flex-col gap-3 text-[#8A8C8C] lg:w-1/2">
      <h2 class="text-white text-xl font-bold uppercase">Legal</h2>
      <a href="#">Legal Notice</a>
      <a href="#">Privacy Policy</a>
      <a href="#">Terms of Use</a>
    </div>
  </div>
  <div class="flex flex-col gap-4 w-[20rem] lg:w-1/4">
    <h2 class="text-white text-xl font-bold uppercase">Follow Us</h2>
    <div class="flex gap-5">
      <a href="#">
        <img src="../../../../assets/facebook.png" alt="Facebook logo" />
      </a>
      <a href="#">
        <img src="../../../../assets/twitter.png" alt="Twitter logo" />
      </a>
      <a href="#">
        <img src="../../../../assets/instagram.png" alt="Instagram logo" />
      </a>
      <a href="#">
        <img src="../../../../assets/linkedin.png" alt="Linkedin logo" />
      </a>
    </div>
    <h2 class="text-white text-xl font-bold uppercase">Talk to us</h2>
    <p class="text-[#737373]">
      Call or text: <a href="tel:+19018701706"></a> +1(901)870-1706 <br />
      Email:
      <a href="mailto:cade@wildveldmotors.com">cade@wildveldmotors.com</a>
    </p>
  </div>
</div>

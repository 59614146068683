import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-filter-input',
  templateUrl: './filter-input.component.html',
  styleUrls: ['./filter-input.component.scss'],
})
export class FilterInputComponent {
  @Input() placeholder: string;
  @Input() options: string[];
  @Input() icon: string;
  @Input() styleClass: string;
  @Output() emitValue: EventEmitter<string> = new EventEmitter<string>();

  changeValue(event: MatSelectChange): void {
    this.emitValue.emit(event.value as string);
  }
}

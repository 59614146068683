<div class="flex gap-3">
  <button
    class="bg-white p-3 text-[#FE6313] font-bold rounded-lg flex justify-center items-center border-gray-200 border-2 hover:bg-[#FE6313] hover:text-white transition-all"
    (click)="goBack()"
  >
    <mat-icon>arrow_backward</mat-icon>
  </button>
  <div class="flex flex-col">
    <h3 class="text-md text-gray-400">
      {{ second }}
    </h3>
    <h2 class="text-[#FE6313] text-3xl font-bold text-[roboto]">
      {{ title }}
    </h2>
  </div>
</div>

<div class="w-full h-full flex flex-col justify-center items-center p-5 gap-5">
  <h1 class="text-start w-full text-[1.7rem] mt-[.8rem] font-bold">
    Upload Image
  </h1>
  <image-cropper
    style="width: 100%"
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="data.aspectRatio ? data.aspectRatio : 1"
    [resizeToWidth]="512"
    [cropperMinWidth]="128"
    [alignImage]="'left'"
    [style.display]="showCropper ? null : 'none'"
    format="png"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
  ></image-cropper>
  <div class="w-full">
    <app-button label="Save" styleClass="w-full" (click)="save()"></app-button>
  </div>
</div>

<div
  class="transition-all fixed top-0 h-[8rem] w-full lg:bg-[#0F10147F] bg-[#0F1014] lg:backdrop-blur flex items-center justify-between px-[2rem] z-50"
>
  <div>
    <a routerLink="/">
      <img
        src="../../../../assets/Logo.png"
        alt="Page Logo"
        class="w-[10rem] md:w-auto"
      />
    </a>
  </div>
  <div
    class="hidden lg:flex items-center justify-center uppercase gap-5 text-white text-lg font-bold [&>a:hover]:text-[#FE6313] [&>.active]:text-[#FE6313] [&>a]:transition-colors"
  >
    <a routerLink="defenders" routerLinkActive="active">Vehicles</a>
    <a routerLink="restoration" routerLinkActive="active">Restoration</a>
    <a routerLink="shop" routerLinkActive="active">Conservation</a>
    <a routerLink="faq" routerLinkActive="active">Faq</a>
    <a routerLink="about" routerLinkActive="active">About</a>
    <div>
      <a
        class="bg-[#FE6313] px-10 py-1 hover:brightness-75 transition-all [&:hover>mat-icon]:rotate-[90deg] flex justify-center items-center gap-3"
        routerLink="contact"
      >
        <span class="line-clamp-1">Contact us</span>
        <mat-icon class="rotate-[45deg] transition-all"
          >arrow_upward</mat-icon
        ></a
      >
    </div>
  </div>
  <button class="block lg:hidden" (click)="toggleNavbar()">
    <svg viewBox="0 0 100 80" width="40" height="40" fill="white">
      <rect width="100" height="10"></rect>
      <rect y="30" width="100" height="10"></rect>
      <rect y="60" width="100" height="10"></rect>
    </svg>
  </button>
</div>
<div
  class="fixed top-0 left-0 h-screen w-screen z-50 bg-black p-[2rem] flex flex-col"
  *ngIf="collapsed"
>
  <div class="flex justify-end">
    <button class="block lg:hidden text-white" (click)="toggleNavbar()">
      <img src="assets/svgs/navbar_toggle.svg" alt="Toggle" />
    </button>
  </div>
  <div
    class="flex flex-col items-start justify-center uppercase text-white text-3xl font-bold gap-[3rem] h-screen"
    (click)="toggleNavbar()"
  >
    <a routerLink="defenders" routerLinkActive="active">Vehicles</a>
    <a routerLink="restoration" routerLinkActive="active">Restoration</a>
    <a routerLink="shop" routerLinkActive="active">Conservation</a>
    <a routerLink="faq" routerLinkActive="active">Faq</a>
    <a routerLink="about" routerLinkActive="active">About</a>
    <a routerLink="contact">Contact us</a>
  </div>
</div>

import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  collapsed = false;
  @Output() toggleCollapsed = new EventEmitter();

  toggleNavbar(): void {
    this.collapsed = !this.collapsed;
    this.toggleCollapsed.emit(this.collapsed);
  }
}

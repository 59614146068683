<div
  class="bg-white rounded-lg p-5 max-w-full w-[34rem] h-[20rem] flex justify-center items-center {{
    styleClass
  }}"
  [ngClass]="{
    'border-2 border-red-500':
      imageFormControl.invalid && imageFormControl.touched
  }"
  [class.ng-invalid]="imageFormControl.invalid && imageFormControl.touched"
>
  <div
    class="w-full h-full relative hover:cursor-pointer"
    *ngIf="imageFormControl.value && !isLoading"
  >
    <img
      [src]="imageFormControl.value + '/l'"
      alt="file upload"
      class="w-full h-full object-contain rounded-lg {{ imgStyleClass }}"
    />
    <div
      class="absolute inset-0 transition-all hover:bg-[#00000099] [&>button]:hidden [&:hover>button]:block flex justify-center items-center rounded-lg flex flex-col gap-5 text-white"
    >
      <button
        class="bg-[#fe6313] p-2 rounded-lg w-[10rem] hover:brightness-75"
        type="button"
        (click)="open()"
      >
        View image
      </button>
      <button
        class="bg-red-600 p-2 rounded-lg w-[10rem] hover:brightness-75"
        type="button"
        (click)="removeImage()"
      >
        Remove image
      </button>
    </div>
  </div>
  <div
    class="h-full w-full flex justify-center items-center border-2 border-dashed border-[#FE6313] rounded-lg bg-gray-100 {{
      inputStyleClass
    }}"
    *ngIf="!imageFormControl.value && !isLoading"
  >
    <div
      class="w-full h-full flex justify-center items-center cursor-pointer relative"
    >
      <input
        type="file"
        id="profileImage"
        class="border-2 w-full h-full absolute z-10 opacity-0 hover:cursor-pointer"
        accept="image/png, image/gif, image/jpeg"
        (change)="fileChangeEvent($event)"
      />
      <div class="flex flex-col gap-5 justify-center items-center">
        <img src="assets/svgs/upload_image.svg" alt="Upload image icon" />
        <p class="text-center text-gray-400">
          <span class="text-[#FE6313] underline">Click to upload</span> or
          <br />
          drag and drop
        </p>
      </div>
    </div>
  </div>
  <div *ngIf="isLoading">
    <mat-spinner color="warn"></mat-spinner>
  </div>
</div>
<span
  class="mt-3 text-red-500 text-sm"
  *ngIf="imageFormControl.invalid && imageFormControl.touched"
>
  Cover image is required
</span>

import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CropperModalComponent } from './cropper-modal/cropper-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MediaOneResponseDTO } from '../../../_core/DTOs/MediaOneResponseDTO';
import { MediaControllerService } from '../../../_core/api/media-controller.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IAlbum, Lightbox, LightboxConfig } from 'ngx-lightbox';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
  providers: [MatSnackBar],
})
export class ImageUploaderComponent {
  @Input() imageFormControl: FormControl;
  @Input() styleClass: string;
  @Input() inputStyleClass: string;
  @Input() imgStyleClass: string;
  @Input() cropperAspectRatio: number;
  isLoading: boolean;
  croppedImage: File;
  constructor(
    private dialogRef: MatDialog,
    private mediaController: MediaControllerService,
    private snackBar: MatSnackBar,
    private lightbox: Lightbox,
    private dialog: MatDialog,
    private lightboxConfig: LightboxConfig
  ) {
    lightboxConfig.centerVertically = true;
    lightboxConfig.disableScrolling = true;
    lightboxConfig.wrapAround = true;
  }

  fileChangeEvent(event: Event): void {
    const target = event.target as HTMLInputElement;
    const dialog = this.dialogRef.open(CropperModalComponent, {
      height: '30rem',
      width: '80rem',
      data: { e: event, aspectRatio: this.cropperAspectRatio },
    });
    dialog.afterClosed().subscribe((res: { data: File }) => {
      if (res !== undefined) {
        this.croppedImage = res.data;
        this.imageFormControl.markAsTouched();
        this.isLoading = true;
        this.mediaController.uploadImage(this.croppedImage).subscribe({
          next: (res: MediaOneResponseDTO) => {
            this.imageFormControl.setValue(res.url);
            this.isLoading = false;
          },
          error: () => {
            this.imageFormControl.setValue(null);
            this.snackBar.open('Upload failed', 'Close', { duration: 3000 });
            this.isLoading = false;
          },
        });
        this.imageFormControl.setValue(this.croppedImage);
      }
      target.value = '';
    });
  }

  open(): void {
    // open lightbox
    this.lightbox.open(
      [{ src: (this.imageFormControl.value as string) + '/xl' } as IAlbum],
      0
    );
  }

  removeImage(): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: 'Delete image',
          content: 'Are you sure you want to delete this image?',
          confirmButtonLabel: 'Delete',
          dismissButtonLabel: 'Cancel',
          customSize: false,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.imageFormControl.setValue('');
        }
      });
  }
}

<div class="flex items-end justify-center gap-3">
  <div class="flex-1 flex justify-center items-center">
    <div
      [class]="
        'flex flex-col px-4 pt-2 pb-4 border-2 border-gray-300 [&:has(input:focus)]:border-[#FE6313] [&:has(.ng-touched.ng-invalid)]:[&>.error-message]:block [&:has(.ng-touched.ng-invalid)]:border-[red] rounded-lg w-full transition-all ' +
        styleClass
      "
      *ngIf="
        labelType === LabelType.InnerLabel && inputType !== InputType.DROPDOWN
      "
    >
      <span class="text-gray-500 text-xs">{{ label }}</span>
      <div class="flex-1 w-full flex">
        <input
          type="text"
          class="outline-0 flex-1"
          [type]="inputType"
          [formControl]="control"
          [name]="name"
          [placeholder]="placeholder ? placeholder : ''"
          [autocomplete]="autocomplete"
          ngDefaultControl
        />
        <mat-icon
          *ngIf="isOfPasswordType && !isVisible"
          class="cursor-pointer opacity-50"
          (click)="togglePasswordVisible()"
          >visibility</mat-icon
        >
        <mat-icon
          *ngIf="isOfPasswordType && isVisible"
          class="cursor-pointer opacity-50"
          (click)="togglePasswordVisible()"
          >visibility_off</mat-icon
        >
      </div>
    </div>
    <div
      [class]="'flex flex-col gap-2 w-full ' + styleClass"
      *ngIf="
        labelType === LabelType.OuterLabel && inputType !== InputType.DROPDOWN
      "
    >
      <span class="text-gray-400 text-sm font-light">{{ label }}</span>
      <div
        class="[&:has(input[disabled])]:bg-[#EFEFEF] flex-1 w-full flex justify-center items-center border-2 border-gray-300 px-4 py-3 rounded [&:has(input:focus)]:border-[#FE6313] [&:has(.ng-touched.ng-invalid)]:border-[red] transition-all rounded-lg"
      >
        <button
          *ngIf="inputType === InputType.NUMBER"
          class="w-5rem cursor-default text-[#FE6313] bg-gray-300 ml-[-0.5rem] mr-[1rem] px-3 py-1 rounded"
        >
          $
        </button>
        <input
          type="text"
          class="outline-0 flex-1 h-[1.8rem]"
          [type]="inputType"
          [name]="name"
          [formControl]="control"
          [placeholder]="placeholder ? placeholder : ''"
          [autocomplete]="autocomplete"
          *ngIf="inputType !== InputType.TEXTAREA"
        />
        <textarea
          [formControl]="control"
          *ngIf="inputType === InputType.TEXTAREA"
          [placeholder]="placeholder ? placeholder : ''"
          [class]="'w-full border-0 outline-0 resize-none ' + areaHeight"
        >
        </textarea>
        <img
          src="assets/svgs/password_toggle.svg"
          *ngIf="isOfPasswordType"
          class="cursor-pointer"
          (click)="togglePasswordVisible()"
          alt="Password toggle"
        />
      </div>
    </div>
    <div
      [class]="'flex flex-col gap-2 w-full ' + styleClass"
      *ngIf="inputType === InputType.DROPDOWN"
    >
      <span class="text-gray-400 text-sm font-light">{{ label }}</span>
      <mat-form-field appearance="fill" class="w-full">
        <mat-select [formControl]="control">
          <mat-option *ngFor="let item of list" [value]="item">
            {{ item }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <button
    class="h-[55px] w-[55px] bg-[#FE6313] inline-block p-3 rounded-lg flex justify-center items-center hover:brightness-75"
    (click)="toggleFIeldDisabled()"
    *ngIf="toggleButton"
  >
    <img
      src="assets/images/Users/edit-icon.svg"
      alt="edit icon"
      class="object-cover"
    />
  </button>
</div>

<div class="flex flex-col mt-1">
  <span
    class="text-red-500 text-sm error-message"
    *ngFor="let error of errorsArray"
  >
    {{ error }}
  </span>
</div>

import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {
  catchError,
  concatMap,
  exhaustMap,
  first,
  Observable,
  Subject,
  throwError,
} from 'rxjs';
import StorageHelper from '../../_core/helpers/storage.helper';
import { Router } from '@angular/router';
import { AccountControllerService } from '../../_core/api/account-controller.service';
import { UserService } from '../../_core/services/user.service';
import { AccountRefreshTokenResponseDTO } from '../../_core/DTOs/AccountRefreshTokenResponseDTO';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  private _isRefreshing = false;
  private _refreshTokenSubject: Subject<string> = new Subject<string>();

  constructor(
    private accountController: AccountControllerService,
    private router: Router,
    private userService: UserService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(this.setHeaders(request)).pipe(
      catchError((value: HttpErrorResponse) => {
        if (value.status === 0 || value.status === 502)
          this.router.navigate(['/auth/login'], { skipLocationChange: true });
        if (
          value?.error?.length &&
          value?.error[0].includes('Invalid token.')
        ) {
          return this.handleRefreshToken(request, next);
        }
        return throwError(value);
      })
    );
  }

  handleRefreshToken(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this._isRefreshing) {
      this._isRefreshing = true;
      StorageHelper.deleteToken();
      return this.accountController
        .refreshToken({ refreshToken: StorageHelper.getRefreshToken() })
        .pipe(
          exhaustMap(
            (
              response: AccountRefreshTokenResponseDTO
            ): Observable<HttpEvent<any>> => {
              StorageHelper.saveToken(response.accessToken);
              this._refreshTokenSubject.next(response.accessToken);
              this._isRefreshing = false;
              return next.handle(this.setHeaders(req));
            }
          )
        );
    } else {
      return this._refreshTokenSubject.pipe(
        first(),
        concatMap(
          (): Observable<HttpEvent<any>> => next.handle(this.setHeaders(req))
        )
      );
    }
  }

  setHeaders(request: HttpRequest<any>): HttpRequest<any> {
    let headers = request.headers.set('Access-Control-Allow-Origin', '*');
    if (StorageHelper.getToken()) {
      headers = headers.set('A', `${StorageHelper.getToken()}`);
    }
    return request.clone({ headers });
  }
}

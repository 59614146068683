import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Vehicle } from '../../../_core/models/vehicle';
import { Merch } from '../../../_core/models/merch';
import { WindowRefService } from '../../../_core/services/window-ref.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Router } from '@angular/router';

@Component({
  selector: 'app-carrousel',
  templateUrl: './carrousel.component.html',
  styleUrls: ['./carrousel.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(200, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate(200, style({ opacity: 0 })),
      ]),
      state('closed', style({ opacity: 1 })),
    ]),
  ],
})
export class CarrouselComponent implements OnInit, OnDestroy {
  @Input() slides: Partial<Vehicle | Merch>[];
  @Input() hasBottomCard: boolean;
  @Input() isLoading = true;
  @Input() noElementsMessage = "There aren't any elements to show.";
  @Input() hasLink = true;
  window: Window;

  currentIndex = 0;
  timeoutId?: number;
  slideshowDuration = 5000;

  constructor(private windowRef: WindowRefService, private router: Router) {
    this.window = this.windowRef.nativeWindow;
  }

  ngOnInit(): void {
    this.resetTimer();
  }

  ngOnDestroy(): void {
    this.window.clearTimeout(this.timeoutId);
  }

  resetTimer(): void {
    if (this.timeoutId) {
      this.window.clearTimeout(this.timeoutId);
    }
    this.timeoutId = this.window.setTimeout(() => {
      this.goToNext();
    }, this.slideshowDuration);
  }

  goToNext(): void {
    const isLastSlide = this.currentIndex === this.slides.length - 1;
    const newIndex = isLastSlide ? 0 : this.currentIndex + 1;

    this.resetTimer();
    this.currentIndex = newIndex;
  }

  goToSlide(slideIndex: number): void {
    this.resetTimer();
    this.currentIndex = slideIndex;
  }

  getCurrentSlideUrl(): string {
    return `${this.slides?.at(this.currentIndex).avatar + '/xl'}`;
  }

  navigateToSlideUrl(url: string): void {
    if (this.hasLink) this.router.navigate([url]);
  }
}

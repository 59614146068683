import { Component, Inject, OnInit } from '@angular/core';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageUploaderComponent } from '../image-uploader.component';
import { v4 as uuidv4 } from 'uuid';
import { MatSnackBar } from '@angular/material/snack-bar';

interface IDialogData {
  e: Event;
  aspectRatio: number;
}

@Component({
  selector: 'app-cropper-modal',
  templateUrl: './cropper-modal.component.html',
  styleUrls: ['./cropper-modal.component.scss'],
  providers: [MatSnackBar],
})
export class CropperModalComponent implements OnInit {
  imageChangedEvent = this._data.e;
  croppedImage = '';
  showCropper = false;
  transform: ImageTransform = {};
  data: IDialogData;
  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: IDialogData,
    private dialogRef: MatDialogRef<ImageUploaderComponent>
  ) {}

  ngOnInit(): void {
    this.data = this._data;
    this.fileChangeEvent(this.data.e);
  }

  fileChangeEvent(event: Event): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }

  imageLoaded(): void {
    this.showCropper = true;
  }

  dataURLtoFile(dataurl: string, filename: string): File {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[arr.length - 1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename + '.' + mime.split('/')[1], {
      type: mime,
    });
  }

  save(): void {
    this.dialogRef.close({
      data: this.dataURLtoFile(this.croppedImage, uuidv4()),
    });
  }
}

import { Component, Input } from '@angular/core';
import { InputType } from '../../../_core/enums/InputType';
import { LabelType } from '../../../_core/enums/LabelType';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-element-name-price-category-form',
  templateUrl: './element-name-price-category-form.component.html',
  styleUrls: ['./element-name-price-category-form.component.scss'],
})
export class ElementNamePriceCategoryFormComponent {
  InputType = InputType;
  LabelType = LabelType;
  @Input() labelForName: string;
  @Input() labelForPrice: string;
  @Input() labelForFirstCategory: string;
  @Input() labelForSecondCategory: string;
  @Input() labelForFirstForm: string;
  @Input() firstCategoryList: string[];
  @Input() secondCategoryList: string[];
  @Input() form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    price: new FormControl(0, [Validators.required]),
    firstCategory: new FormControl('', [Validators.required]),
    secondCategory: new FormControl('', [Validators.required]),
  });

  get name(): FormControl {
    return this.form.controls.name;
  }

  get price(): FormControl {
    return this.form.controls.price;
  }

  get firstCategory(): FormControl {
    return this.form.controls.firstCategory;
  }

  get secondCategory(): FormControl {
    return this.form.controls.secondCategory;
  }
}

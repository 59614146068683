import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InputType } from '../../../_core/enums/InputType';
import { LabelType } from '../../../_core/enums/LabelType';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
  @Input() styleClass: string;
  @Input() label: string;
  @Input() inputType = InputType.TEXT;
  @Input() labelType = LabelType.InnerLabel;
  @Input() control: FormControl;
  @Input() name: string;
  @Input() list: string[];
  @Input() placeholder: string;
  @Output() selectedValueChange = new EventEmitter<string>();
  @Input() selectedValue: string;
  @Input() areaHeight = 'h-[20rem]';
  @Input() autocomplete: string;
  errorsArray: string[] = [];
  isOfPasswordType = false;
  LabelType = LabelType;
  InputType = InputType;
  isVisible = false;
  @Input() toggleButton = false;

  ngOnInit(): void {
    this.isOfPasswordType = this.inputType === InputType.PASSWORD;
    this.control.valueChanges.subscribe({
      next: () => {
        this.updateErrors();
      },
    });
  }

  toggleFIeldDisabled(): void {
    if (this.control.disabled) this.control.enable();
    else this.control.disable();
  }

  togglePasswordVisible(): void {
    this.isVisible = !this.isVisible;
    if (this.inputType === InputType.TEXT) this.inputType = InputType.PASSWORD;
    else this.inputType = InputType.TEXT;
  }

  updateErrors(): void {
    this.errorsArray = this.errors;
  }

  get errors(): string[] {
    if (this.control.errors)
      return Object.keys(this?.control?.errors)
        .filter((key) => this.control.errors[key])
        .map((err) => {
          switch (err) {
            case 'required':
              return 'This field is required.';
            case 'email':
              return 'Email is invalid';
            case 'maxlength':
              return 'The value exceeds the maximum length.';
            case 'minlength':
              return 'The value of the field is too short.';
            case 'min':
              return 'The value of the field is too small.';
            case 'notYoutubeLink':
              return 'The value should be a valid youtube link.';
            case 'url':
              return 'The value should be a valid url.';
            case 'pattern':
              return 'The value of the field is invalid.';
            default:
              return err[0].toUpperCase() + err.slice(1);
          }
        });
    else return [];
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() src: string;
  @Input() title: string;
  @Input() price: number;
  @Input() description: string;
  @Input() editUrl: string;
  @Input() alt: string;
  @Input() id: string;
  @Input() isAdmin: boolean;
  @Output() removeSelf = new EventEmitter<string>();

  removeVehicle(): void {
    this.removeSelf.emit(this.id);
  }
}

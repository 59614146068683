<div class="flex flex-col lg:flex-row gap-8 overflow-hidden">
  <div class="flex-1 min-h-[16rem]">
    <h2>{{ labelForFirstForm ? labelForFirstForm : "Vehicle" }}</h2>
    <div class="flex flex-col bg-white p-5 gap-5 rounded-[.4rem]">
      <app-input
        [labelType]="LabelType.OuterLabel"
        label="{{ labelForName ? labelForName : 'Product Name' }}"
        [control]="name"
      ></app-input>
      <app-input
        [labelType]="LabelType.OuterLabel"
        label="{{ labelForPrice ? labelForPrice : 'Estimated Price' }}"
        [inputType]="InputType.NUMBER"
        [control]="price"
      ></app-input>
    </div>
  </div>
  <div class="flex-1 h-[16rem]">
    <h2>Category</h2>
    <div class="flex flex-col bg-white p-5 rounded-[.4rem]">
      <app-input
        [labelType]="LabelType.OuterLabel"
        [inputType]="InputType.DROPDOWN"
        [list]="firstCategoryList"
        [control]="firstCategory"
        label=" {{
          labelForFirstCategory ? labelForFirstCategory : 'Vehicle Category'
        }}"
      ></app-input>
      <app-input
        [labelType]="LabelType.OuterLabel"
        [inputType]="InputType.DROPDOWN"
        [list]="secondCategoryList"
        [control]="secondCategory"
        label="{{
          labelForSecondCategory ? labelForSecondCategory : 'Vehicle Category'
        }}"
      ></app-input>
    </div>
  </div>
</div>

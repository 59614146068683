<div
  class="flex flex-wrap bg-white p-5 gap-5 rounded-lg"
  [ngClass]="{
    'border-2 border-red-500 ng-invalid':
      imageFormControl.invalid && imageFormControl.touched
  }"
>
  <div
    class="bg-white rounded-lg p-5 max-w-full w-[15rem] h-[15rem] flex justify-center items-center"
    *ngIf="!isLoading"
  >
    <div
      class="h-full w-full flex justify-center items-center border-2 border-dashed border-[#FE6313] rounded-lg bg-gray-100"
    >
      <div
        class="w-full h-full flex justify-center items-center cursor-pointer relative"
      >
        <input
          type="file"
          id="profileImage"
          class="border-2 w-full h-full absolute z-10 opacity-0 hover:cursor-pointer"
          accept="image/png, image/gif, image/jpeg"
          (change)="fileChangeEvent($event)"
        />
        <div class="flex flex-col gap-5 justify-center items-center">
          <img src="assets/svgs/upload_image.svg" alt="Upload image icon" />
          <p class="text-center text-gray-400">
            <span class="text-[#FE6313] underline">Click to upload</span> or
            <br />
            drag and drop
          </p>
        </div>
      </div>
    </div>
  </div>
  <div
    class="bg-white rounded-lg p-5 max-w-full w-[15rem] h-[15rem] flex justify-center items-center"
    *ngIf="isLoading"
  >
    <div class="rounded-lg h-full w-full flex justify-center items-center">
      <mat-spinner color="warn"></mat-spinner>
    </div>
  </div>
  <div
    class="w-[15rem] h-[15rem] relative"
    *ngFor="let imageString of imageFormControl.value; let i = index"
  >
    <img
      [src]="imageString + '/l'"
      alt="file upload"
      class="w-full h-full object-contain rounded-lg"
    />
    <div
      class="absolute inset-0 transition-all hover:bg-[#00000099] [&>button]:hidden [&:hover>button]:block flex justify-center items-center rounded-lg flex flex-col gap-5 text-white"
    >
      <button
        class="bg-[#fe6313] p-2 rounded-lg w-[10rem] hover:brightness-75"
        type="button"
        (click)="open(i)"
      >
        View image
      </button>
      <button
        class="bg-red-600 p-2 rounded-lg w-[10rem] hover:brightness-75"
        type="button"
        (click)="removeImage(i)"
      >
        Remove image
      </button>
    </div>
  </div>
</div>
<div
  class="flex flex-col mt-3"
  *ngIf="imageFormControl.invalid && imageFormControl.touched"
>
  <span class="text-red-500 text-sm error-message" *ngFor="let error of errors">
    {{ error }}
  </span>
</div>
